import React, { useState, useContext, useEffect } from 'react';

export type Context = {
  toggleMenu: boolean;
  setToggleMenu: (toggleMenu: boolean) => void;
  updateToggleMenu: () => void;
};

export type Provider = Children;

const ToggleMenuContext = React.createContext<Context>({} as Context);

export function useToggleMenu(): Context {
  return useContext(ToggleMenuContext);
}

export function ToggleMenuProvider({ children }: Provider): React.ReactElement {
  const [toggleMenu, setToggleMenu] = useState(false);

  useEffect(() => {
    function changeWindowSize(): void {
      if (window.innerWidth <= 768) {
        setToggleMenu(true);
      } else {
        setToggleMenu(false);
      }
    }
    changeWindowSize();

    window.addEventListener('resize', changeWindowSize);
    return () => {
      window.removeEventListener('resize', changeWindowSize);
    };
  }, []);

  function updateToggleMenu(): void {
    setToggleMenu(!toggleMenu);
  }

  const value = {
    toggleMenu,
    setToggleMenu,
    updateToggleMenu,
  };

  return <ToggleMenuContext.Provider value={value}>{children}</ToggleMenuContext.Provider>;
}
