import axios from 'axios';

// utils
import getTokenStorage from '../utils/getTokenStorage';


export const verifyUrl = (): string => {
  if (window.location.hostname !== 'localhost') {
    return `${process.env.REACT_APP_API_URL}`;
  }

  return `${process.env.REACT_APP_API_URL}`;
};

const defaultHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
};

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  timeout: 30000,
  headers: defaultHeaders,
});

api.defaults.headers.common['x-access-token'] = getTokenStorage();

export default api;
