import React from 'react';
import { NavLink } from 'react-router-dom';
import { Navbar } from 'react-bootstrap';
import { HiUser, HiHome, HiUpload } from 'react-icons/hi';

// components
import Text from '../Text';

// services
import { useToggleMenu } from '../../contexts/ToggleMenuContext';

// styles
import './styles.scss';

const Sidebar: React.FunctionComponent = () => {
  const { toggleMenu } = useToggleMenu();

  return (
    <Navbar className={`sidebar align-items-start flex-column w-100 ${toggleMenu && 'min-width'}`}>
      <div className="sidebar__brand mx-auto">
        <img src="https://softdesign.com.br/wp-content/themes/softdesign/assets/images/softdesign.svg" alt="logo" />
      </div>
      <div className="sidebar__routes w-100">
        <ul className="sidebar__routes--ul d-flex flex-wrap flex-column">
          <li className="d-flex align-items-center">
            <NavLink to="/" exact className="sidebar__routes--link w-100" activeClassName="sidebar__routes--active">
              <HiHome size={22} />{' '}
              <Text as="span" className="sidebar__routes--title">
                Home
              </Text>
            </NavLink>
          </li>
          <li className="d-flex align-items-center">
            <NavLink
              to="/central-importacoes"
              className="sidebar__routes--link w-100"
              activeClassName="sidebar__routes--active"
            >
              <HiUser size={22} />{' '}
              <Text as="span" className="sidebar__routes--title">
                Central de importações
              </Text>
            </NavLink>
          </li>
          <li className="d-flex align-items-center">
            <NavLink
              to="/upload-arquivo"
              className="sidebar__routes--link w-100"
              activeClassName="sidebar__routes--active"
            >
              <HiUpload size={22} />{' '}
              <Text as="span" className="sidebar__routes--title">
                Upload de arquivos
              </Text>
            </NavLink>
          </li>
        </ul>
      </div>
    </Navbar>
  );
};

export default Sidebar;
