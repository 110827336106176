import React from 'react';
import { Route, Redirect, RouteProps, RouteComponentProps } from 'react-router-dom';
import checkTokenIsValid from '../../utils/checkTokenIsValid';

type PrivateRoute = {
  component: React.FunctionComponent<RouteComponentProps>;
} & RouteProps;

export default function PrivateRoute({ component: Component, ...rest }: PrivateRoute): React.ReactElement {
  const currentUser  = checkTokenIsValid('token');

  if (!currentUser) return <Redirect to={`${process.env.PUBLIC_URL}/login`} />;

  return <Route {...rest} render={(props) => <Component {...props} />} />;
}
