/* eslint-disable no-console */
import React, { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { nanoid } from 'nanoid';

// services
import { uploadFile } from '../../services/file.service';

type Props = Children;

type Files = {
  setFiles: (dataFiles: FileList | null) => void;
  files: FormatedFile[];
  removeFile: (id: string) => void;
  deleteFile: (id: string) => void;
  cleanFiles: () => void;
};

type FormatedFile = {
  data: File;
  progress: number;
  fileName: string;
  size: number;
  id: string;
};

type CancellationControllers = {
  [key: string]: AbortController;
};

const FilesContext = createContext<Files | null>(null);
const cancellationControllers: CancellationControllers = {};

export const FilesProvider = ({ children }: Props): React.ReactElement => {
  const [files, _setFiles] = useState<FormatedFile[]>([]);

  const updateFile = useCallback((id: string, progress: number) => {
    _setFiles((prevFiles) => prevFiles.map((file) => (file.id === id ? { ...file, progress } : file)));
  }, []);

  const processUpload = useCallback(
    (file: FormatedFile) => {
      const data = new FormData();
      data.append('file', file.data, file.fileName);

      const abortController = new AbortController();
      cancellationControllers[file.id] = abortController;

      try {
        uploadFile({ data, fileId: file.id, updateFile, abortController });
      } catch (error) {
        console.error(error);
      }
    },
    [updateFile]
  );

  const setFiles = useCallback(
    (dataFiles: FileList | null): void => {
      if (dataFiles) {
        const filesArray = Array.from(dataFiles) as File[];
        const filesCollection = filesArray.map<FormatedFile>((file) => ({
          data: file,
          progress: 0,
          fileName: file.name,
          size: file.size,
          status: 'Added',
          id: nanoid(),
        }));
        _setFiles((prev) => prev.concat(filesCollection));
        filesCollection.forEach(processUpload);
      }
    },
    [processUpload]
  );

  const cleanFiles = (): any => {
    _setFiles([])
  }

  const deleteFile = useCallback((id: string) => {
    _setFiles((prev) => prev.filter((file) => file.id !== id));
  }, []);

  const removeFile = useCallback(
    (id: string) => {
      cancellationControllers[id]?.abort();
      deleteFile(id);
    },
    [deleteFile]
  );

  const contextValue = useMemo(
    () => ({ setFiles, files, removeFile, deleteFile, cleanFiles }),
    [setFiles, files, removeFile, deleteFile, cleanFiles]
  );

  return <FilesContext.Provider value={contextValue}>{children}</FilesContext.Provider>;
};

export function useFilesContext(): Files {
  const context = useContext(FilesContext);
  if (!context) {
    throw new Error('useFilesContext deve ser usado dentro de um FilesProvider');
  }
  return context;
}
