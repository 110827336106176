import React from 'react';
import ReactDOM from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import App from './App';

// utils
import ToastContainerProps from './utils/toastContainerProps';

// styles
import './theme/main.scss';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);
root.render(
  <React.StrictMode>
    <ToastContainer {...ToastContainerProps} />
    <App />
  </React.StrictMode>
);
