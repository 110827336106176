/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

// components
import Loader from '../../components/Loader';

type Props = Children;

type Api = {
  query: <T>(queryFunction: any, payload?: any) => Promise<T>;
  loading: boolean;
};

const ApiContext = createContext<Api | null>(null);

export const ApiProvider = ({ children }: Props): React.ReactElement => {
  const [loading, setLoading] = useState<boolean>(false);

  const query = useCallback(
    async (queryFunction: any, payload: any) => {
      setLoading(true);
      return queryFunction(payload || null).finally(() => setLoading(false));
    },
    [setLoading]
  );

  useEffect(() => {
    if (loading) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [loading]);

  const contextValue = useMemo(() => ({ loading, query }), [loading, query]);

  return (
    <ApiContext.Provider value={contextValue}>
      {loading ? <Loader /> : null}
      {children}
    </ApiContext.Provider>
  );
};

export function useApiContext(): Api {
  const context = useContext(ApiContext);
  if (!context) {
    throw new Error('useApiContext deve ser usado dentro de um ApiProvider');
  }
  return context;
}
/* eslint-enable @typescript-eslint/no-explicit-any */
