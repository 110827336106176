import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import checkTokenIsValid from '../utils/checkTokenIsValid';

// contexts
// import { useAuth } from '../contexts/AuthContext';

// components;
import PrivateRoute from '../components/PrivateRoute';
import Sidebar from '../components/Sidebar';
import Header from '../components/Header';
import Loader from '../components/Loader';

// routes
import { routes } from './routes';

const Routes: React.FunctionComponent = () => {
  const renderRoutes = (): React.ReactNode => {
    let routesAux = routes.map((route) => {
      if (route.public) {
        return <Route key={route.path} {...route} exact />;
      }
      return <PrivateRoute key={route.path} {...route} exact />;
    });

    if (checkTokenIsValid('token')) {
      routesAux = routesAux.filter((item) => !item.props.public);
    }
    return routesAux;
  };

  const renderBody = (): React.ReactElement => {
    if (checkTokenIsValid('token')) {
      return (
        <div className="d-flex">
          <Sidebar />
          <div className="d-flex flex-column p-0 w-100">
            <main>
              <Header />
              <Container fluid>
                <React.Suspense fallback={<Loader />}>
                  <Switch>{renderRoutes()}</Switch>
                </React.Suspense>
              </Container>
            </main>
          </div>
        </div>
      );
    }

    return (
      <React.Suspense fallback={<Loader />}>
        <Switch>{renderRoutes()}</Switch>
      </React.Suspense>
    );
  };

  return renderBody();
};

export default Routes;
