import React from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

// styles
import './styles.scss';

type Props = {
  cy: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement> &
  ButtonProps;

const MyButton = ({ children, cy, ...props }: Props): React.ReactElement => (
  <Button data-cy={cy} {...props}>
    {children}
  </Button>
);

MyButton.defaultProps = { children: '' };

export default MyButton;
