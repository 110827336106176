import { AxiosProgressEvent } from 'axios';

import api from './api';

type Props = {
  data: FormData;
  fileId: string;
  updateFile: (fileId: string, progress: number) => void;
  abortController: AbortController;
};

export const uploadFile = ({ data, fileId, updateFile, abortController }: Props): Promise<string> =>
  api
    .post('/fake-upload-post', data, {
      onUploadProgress: (e: AxiosProgressEvent) => {
        const progress = Math.round((e.loaded * 100) / Number(e.total || 0));
        updateFile(fileId, progress);
      },
      signal: abortController?.signal,
    })
    .then((res) => res.data)
    .catch((error) => {
      updateFile(fileId, 100);
      throw error;
    });
