import React from 'react';

// imgs
import { ReactComponent as Spinner } from '../../statics/others/loader.svg';

// styles
import './styles.scss';

const Loader = (): React.ReactElement => (
  <div className="loader">
    <Spinner />
  </div>
);

export default Loader;
