import React, { lazy } from 'react';

const Home = lazy(() => import('../pages/Home'));
const Users = lazy(() => import('../pages/Users/List'));
const Actions = lazy(() => import('../pages/Users/Actions'));
const Signup = lazy(() => import('../pages/Signup'));
const Login = lazy(() => import('../pages/Login'));
const FileUpload = lazy(() => import('../pages/FileUpload'));
const UpdateProfile = lazy(() => import('../pages/UpdateProfile'));
const Grid = lazy(() => import('../pages/Grid'));
const ImgUpload = lazy(() => import('../pages/ImgUpload'));
const Error = lazy(() => import('../pages/Error'));

type Props = {
  path: string;
  component: React.FunctionComponent;
  public?: boolean;
};

export const routes: Props[] = [
  {
    path: '/',
    component: Home,
  },
  {
    path: '/central-importacoes',
    component: Users,
  },
  {
    path: '/funcionarios/acao/:id?',
    component: Actions,
  },
  {
    path: '/perfil',
    component: UpdateProfile,
  },
  {
    path: '/criar-conta',
    component: Signup,
    public: true,
  },
  {
    path: '/login',
    component: Login,
    public: true,
  },
  {
    path: '/tabela',
    component: Grid,
  },
  {
    path: '/upload-arquivo',
    component: FileUpload,
  },
  {
    path: '/upload-imagem',
    component: ImgUpload,
  },
  {
    path: '*',
    component: Error,
    public: true,
  },
];
