import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

// contexts
import { ToggleMenuProvider } from './contexts/ToggleMenuContext';
import { ApiProvider } from './contexts/APIContext';
import { FilesProvider } from './contexts/FilesContext';

// routes
import Routes from './routes';

const App: React.FunctionComponent = () => (
  <Router>
    <ApiProvider>
        <ToggleMenuProvider>
          <FilesProvider>
            <Routes />
          </FilesProvider>
        </ToggleMenuProvider>
    </ApiProvider>
  </Router>
);

export default App;
